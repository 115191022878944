//
// Main
//


body {
	background-color: var(--kt-page-bg);
}

// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

.MuiAlert-message {
	font-size: 13px;
}

.link-theme {
	color: #F8B230 !important;
}

.btn-theme {
	border-color: #F8B230 !important;
	background-color: #F8B230 !important;
	--bs-btn-hover-color: #F8B230 !important;
	--bs-btn-hover-bg: #F8B230 !important;
	--bs-btn-hover-border-color: #F8B230 !important;
	--bs-btn-active-bg: #F8B230 !important;
	--bs-btn-active-border-color: #F8B230 !important;
	--bs-btn-disabled-bg: #F8B230 !important;
	--bs-btn-disabled-border-color: #F8B230 !important;

}

.table-responsive.height-442 {
	max-height: 442px;
}

.table-responsive.height-467 {
	max-height: 467px;
}

// Desktop Mode
@include media-breakpoint-up(lg) {

	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		transition: padding-left get($aside-config, transition-speed) ease, margin-right get($aside-config, transition-speed) ease;

		// Aside secondary enabled & aside fixed modes
		.aside-secondary-enabled.aside-fixed & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, width, desktop);
		}

		// Aside secondary enabled, aside fixed modes and aside minimize modes
		.aside-secondary-enabled.aside-fixed[data-kt-aside-minimize="on"] & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, primary-width, desktop);
		}

		// Aside secondary disabled & aside fixed modes
		.aside-secondary-disabled.aside-fixed & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, primary-width, desktop);
		}

		// Fixed header mode
		.header-fixed[data-kt-sticky-header="on"] & {
			padding-top: get($header-config, desktop, default, height);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {

	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {

		// Fixed header mode
		.header-tablet-and-mobile-fixed[data-kt-sticky-header="on"] & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height);
		}
	}
}

.react-datepicker__time-list-item {
	font-size: 16px;
}