//
// Aside extended
//

// General mode(all devices)
.width-100 {
	width: 100%;
}

.nav-link.text-active-primary.me-6 {
	cursor: pointer;
}

.white-space-break-spaces {
	white-space: break-spaces;
}

#kt_drawer_chat_messenger_body .total {
	display: none;
}

#kt_drawer_chat_messenger_body div[data-total="true"][data-index="1"] .total {
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	top: 0;
	border: 0;
	color: #fff;
	text-align: center;
	font-size: 23px;
	display: flex;
	justify-content: center;
	align-items: center;
}

#kt_drawer_chat_messenger_body div[data-total="true"][data-index="1"] .total:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.46);
	z-index: 0;
}

#kt_drawer_chat_messenger_body div[data-total="true"][data-index="1"] .image-input.image-input-outline.cursor-pointer {
	position: relative;
	overflow: hidden;
}

#kt_drawer_chat_messenger_body div[data-total="true"][data-index="1"] .total .pluse {
	position: relative;
	margin: 0 auto;
}

#kt_drawer_chat_messenger_body .image-input-wrapper.w-125px.h-125px.object-fit-contain {
	border: 1px solid var(--kt-card-border-color);
	box-shadow: unset;
}

#kt_drawer_chat_messenger_footer .new_attachemnt .row {
	display: flex;
	flex-wrap: unset;
	overflow-x: scroll;
}

#kt_drawer_chat_messenger_footer .new_attachemnt .image-input-wrapper.w-125px.h-125px.object-fit-contain {
	border: 1px solid var(--kt-card-border-color);
	box-shadow: unset;
}

#kt_drawer_chat_messenger_footer .remove_image.btn.btn-icon.btn-circle.btn-active-color-primary.w-25px.h-25px.bg-body.shadow {
	position: absolute;
	top: -12px;
	right: -12px;
	transform: unset;
	left: unset;
}

#kt_drawer_chat_messenger #kt_drawer_chat_messenger_body {
	height: calc(100% - 433px);
}

#kt_drawer_chat_messenger #kt_drawer_chat_messenger_body .scroll-y.me-n5.pe-5 {
	height: 100%;
}

#kt_drawer_chat_toggle {
	overflow: hidden;
}

.loader {
	position: fixed;
	background-color: #dfdfdf4f;
	width: 100%;
	height: 100%;
	display: block;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
}

#cancel_trip .modal-content .modal-body .swal2-icon {
	border-color: var(--kt-danger);
}

#cancel_trip .swal2-icon.swal2-danger.swal2-icon-show {
	color: var(--kt-danger);
}

.object-fit-contain {
	object-fit: contain;
	background-size: contain !important;
	background-position: center center;
}

.bi.bi-car-front {
	width: 20px;
	height: 19px;
}

.permissions-box {
	border-bottom: 2px solid #e2e2e2;
	margin-bottom: 0px;
	padding-bottom: 20px;
	height: 100%;
}

.col-lg-6.fv-row.px-8.permissions-col {
	margin-bottom: 30px;
}

.col-lg-6.fv-row.px-8.permissions-col:nth-last-child(1),
.col-lg-6.fv-row.px-8.permissions-col:nth-last-child(2) {
	margin-bottom: 0px;
}

.col-lg-6.fv-row.px-8.permissions-col:nth-last-child(1) .permissions-box,
.col-lg-6.fv-row.px-8.permissions-col:nth-last-child(2) .permissions-box {
	border: 0px;
	padding: 0px;
}

.form-switch.form-check-solid .form-check-input:not(:checked) {
	background-position: left center;
	filter: none;
	background-image: var(--kt-form-switch-bg-image);
}

.aside {
	display: flex;
	box-shadow: var(--kt-aside-box-shadow);
	background-color: var(--kt-aside-bg-color);

	// Aside primary panel
	.aside-primary {
		flex-shrink: 0;

		// Aside secondary enabled  mode
		.aside-secondary-enabled & {
			border-right: 1px solid transparent;
		}

		// Aside secondary enabled and aside minimized modes disabled mode
		.aside-secondary-enabled:not([data-kt-aside-minimize="on"]) & {
			border-right-color: var(--kt-border-color);
		}
	}

	// Aside secondary panel
	.aside-secondary {
		overflow: hidden;

		// Workspace
		.aside-workspace {
			width: get($aside-config, width, desktop) - get($aside-config, primary-width, desktop);
			flex-shrink: 0;
		}
	}
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.aside {
		transition: width get($aside-config, transition-speed) ease;

		// Aside primary
		.aside-primary {
			width: get($aside-config, primary-width, desktop);
		}

		// Aside secondary panel
		.aside-secondary {

			// Workspace
			.aside-workspace {
				width: get($aside-config, width, desktop) - get($aside-config, primary-width, desktop);
				flex-shrink: 0;
			}
		}

		// Fixed aside mode
		.aside-fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: get($aside-config, z-index);
		}

		// Static aside mode
		.aside-static & {
			position: relative;
			z-index: 1;
		}

		// Aside secondary enabled
		.aside-secondary-enabled & {
			width: get($aside-config, width, desktop);
		}

		// Aside secondary disabled
		.aside-secondary-disabled & {
			width: get($aside-config, primary-width, desktop);

			.aside-primary {
				border-right: 0;
			}
		}

		// Minimized aside mode
		[data-kt-aside-minimize="on"] & {
			width: get($aside-config, primary-width, desktop);
			transition: width get($aside-config, transition-speed) ease;
		}
	}

	[dir="rtl"] {
		.aside-toggle {
			.svg-icon {
				transform: rotateZ(180deg);
			}

			&.active .svg-icon {
				transform: rotateZ(0deg) !important;
			}
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.aside {
		display: none;
		width: get($aside-config, width, tablet-and-mobile);

		// Aside primary
		.aside-primary {
			width: get($aside-config, primary-width, tablet-and-mobile);
		}

		// Aside secondary
		.aside-secondary {

			// Workspace
			.aside-workspace {
				width: get($aside-config, width, tablet-and-mobile) - get($aside-config, primary-width, tablet-and-mobile);
				flex-shrink: 0;
			}
		}

		// Aside secondary enabled
		.aside-secondary-enabled & {
			width: get($aside-config, width, tablet-and-mobile);
		}

		// Aside secondary disabled
		.aside-secondary-disabled & {
			width: get($aside-config, primary-width, tablet-and-mobile);

			.aside-primary {
				border-right: 0;
			}
		}
	}
}