//
// Header
//
@media print {

	thead,
	tfoot {
		display: none !important
	}

	#printableArea {
		background-color: #fff;
		height: 100%;
		box-shadow: unset;
		padding: 0px;
	}

	#kt_content {
		padding: 0px;
	}

	.printableArea.card-body {
		background-color: #fff;
		height: 100%;
		padding: 0px;
	}

	table {
		background-color: #fff;
	}

	#non-printable {
		display: none !important;
	}

	#printable {
		display: block;
	}

	#kt_content_container {
		padding: 0px;
	}

	#kt_header {
		display: none !important;
	}

	#kt_footer {
		display: none !important;
		padding: 0px !important;
	}

	#kt_aside {
		display: none;
	}

	#root {
		height: auto !important;
		background-color: #fff;
	}

	body {
		background-color: #fff;
	}

	#root-modals {
		display: none;
	}

	.page-title.d-flex.flex-column.align-items-start.justify-content-center.flex-wrap.mt-n5.mt-lg-0.me-lg-2.pb-2.pb-lg-0.mb-5.mb-lg-0 {
		display: none !important;
	}
}

.react-datepicker-wrapper {
	width: 100%;
}

@page {
	size: A4;
}


.generate_pdf {
	position: absolute;
	top: -73px;
	right: 0px;
}

.generate_pdf_container {
	position: relative;
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		height: get($header-config, desktop, default, height);
		transition: left get($aside-config, transition-speed) ease;

		// Fixed header & sticky header modes
		.header-fixed[data-kt-sticky-header="on"] & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($header-config, desktop, fixed, z-index);
			background-color: var(--kt-header-desktop-fixed-bg-color);
			box-shadow: var(--kt-header-desktop-fixed-box-shadow);
			height: get($header-config, desktop, fixed, height);
			padding: 0;
		}

		// Aside fixed, aside secondary enabled and header sticky modes
		.aside-fixed.aside-secondary-enabled[data-kt-sticky-header="on"] & {
			left: get($aside-config, width, desktop);
			transition: left get($aside-config, transition-speed) ease;
		}

		// Aside fixed, aside secondary enabled and header sticky modes
		.aside-fixed.aside-secondary-disabled[data-kt-sticky-header="on"] & {
			left: get($aside-config, primary-width, desktop);
			transition: left get($aside-config, transition-speed) ease;
		}

		// Aside fixed, aside secondary enabled,header sticky & aside minimize modes
		.aside-fixed.aside-secondary-enabled[data-kt-sticky-header="on"][data-kt-aside-minimize="on"] & {
			left: get($aside-config, primary-width, desktop);
			transition: left get($aside-config, transition-speed) ease;
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		height: get($header-config, tablet-and-mobile, default, height);

		// Fixed header and header sticky modes
		.header-tablet-and-mobile-fixed[data-kt-sticky-header="on"] & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($header-config, tablet-and-mobile, fixed, z-index);
			height: get($header-config, tablet-and-mobile, fixed, height);
			min-height: get($header-config, tablet-and-mobile, fixed, height);
			background-color: var(--kt-header-tablet-and-mobile-fixed-bg-color);
			box-shadow: var(--kt-header-tablet-and-mobile-fixed-box-shadow);
		}

		// Page title
		.page-title {
			display: none !important;
		}
	}
}