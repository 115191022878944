//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.content {
		padding: 0;
	}
}

#kt_drawer_chat_messenger_body .bg-light-info p,
#kt_drawer_chat_messenger_body .bg-light-primary p {
	margin-bottom: 0px;
}

.page-item.disabled {
	pointer-events: none;
}

a.text-hover-primary p {
	margin-bottom: 0px;
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.content {
		padding: get($content-spacing, tablet-and-mobile) 0;
	}
}