//
// Root
//

html {
    font-family: sans-serif;
    text-size-adjust: 100%;
}

#delete_staff .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: 3.75em;
}

#delete_staff .modal-content {
    background-color: var(--bs-body-bg);
    padding: 2rem;
    border-radius: 0.475rem;
}

#delete_staff .modal-content .modal-body {
    padding: 0px;
}

#delete_staff .modal-content .modal-footer {
    padding: 0px;
    border: 0px;
    display: flex;
    justify-content: center;
    margin: 1.5rem auto 1rem auto;
}

#delete_staff .modal-content .modal-body .swal2-icon {
    position: relative;
    box-sizing: content-box;
    width: 5em;
    height: 5em;
    margin: 2.5em auto 0.6em;
    border: 0.25em solid transparent;
    border-radius: 50%;
    border-color: var(--kt-warning);
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    user-select: none;
    display: flex;
    justify-content: center;
}

#delete_staff .modal-content .modal-body p {
    font-weight: normal;
    font-size: 1.1rem;
    margin-top: 1.5rem;
    color: var(--bs-gray-800);
    margin: 1em 1.6em 0.3em;
    text-align: center;
}

#delete_staff .modal-content .modal-footer .btn {
    margin: 15px 5px 0;
}

#cancel_trip .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: 3.75em;
}

#cancel_trip .modal-content {
    background-color: var(--bs-body-bg);
    padding: 2rem;
    border-radius: 0.475rem;
}

#cancel_trip .modal-content .modal-body {
    padding: 0px;
}

#cancel_trip .modal-content .modal-footer {
    padding: 0px;
    border: 0px;
    display: flex;
    justify-content: center;
    margin: 1.5rem auto 1rem auto;
}

#cancel_trip .modal-content .modal-body .swal2-icon {
    position: relative;
    box-sizing: content-box;
    width: 5em;
    height: 5em;
    margin: 2.5em auto 0.6em;
    border: 0.25em solid transparent;
    border-radius: 50%;
    border-color: var(--kt-warning);
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    user-select: none;
    display: flex;
    justify-content: center;
}

#cancel_trip .modal-content .modal-body p {
    font-weight: normal;
    font-size: 1.1rem;
    margin-top: 1.5rem;
    color: var(--bs-gray-800);
    margin: 1em 1.6em 0.3em;
    text-align: center;
}

#cancel_trip .modal-content .modal-footer .btn {
    margin: 15px 5px 0;
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $root-font-size !important;
    font-weight: $font-weight-base;
    font-family: $font-family-sans-serif;

    // Tablet mode
    @include media-breakpoint-down(lg) {
        font-size: $root-font-size-lg !important;
    }

    // Mobile mode
    @include media-breakpoint-down(md) {
        font-size: $root-font-size-md !important;
    }
}

body {
    display: flex;
    flex-direction: column;

    a:hover,
    a:active,
    a:focus {
        text-decoration: none !important;
    }
}

canvas {
    user-select: none;
}

// Angular integration
router-outlet {
    display: none;
}